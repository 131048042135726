
@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap');

.container{
    background: linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../staticImages/bg_1.jpg");
    background-repeat: no-repeat;
   background-size: cover;
   background-attachment: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100dvh;
    position: relative;
    overflow: hidden;
}


.game_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
.title{
    text-transform: uppercase;
font-family: 'Protest Riot', sans-serif;
    color:rgb(229, 128, 51);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
    letter-spacing: 0.2rem;
    font-size: 3rem;
    font-weight: 900;
}

.title_image{
    width: 35rem;
    height: 5rem;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.sub_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-20%);
}

.player_turn_indicator{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player_turn_indicator h1{
    font-size: 3.5rem;
    color: rgb(245, 241, 33);
    -webkit-text-stroke-width: 0.1rem;
    -webkit-text-stroke-color: black;
    font-family: 'Montserrat',sans-serif;
    font-family: 'Protest Riot', sans-serif;
}

.grid_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.bg_img{
    position: absolute;
    width: 35rem;
    height: 30rem;
    object-fit:scale-down;
}

.grid{
    display: grid;
    grid-template-columns: repeat(3,8rem);
    width: 35rem;
    justify-content: center; 
    align-items: center; 
    z-index: 99;
    border-radius: 2rem;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.tile_one,.tile_two,.tile_three,.tile_four,.tile_five,.tile_six,.tile_seven,.tile_eight,.tile_nine{
    border: 1px solid grey;
    width: 8rem;
    height: 6rem;
    border-radius: 0.2rem;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Protest Revolution', sans-serif;
    font-size: 6rem;
    font-weight: 100;
}

.x_img_container{
    position: absolute;
    top: -10%;
    left: -10%;
    transform: translate(-10%,-10%);
}
.x_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.circle_img_container{
    position: absolute;
    top: -10%;
    right: -10%;
    transform: translate(-10%,-10%);
}
.circle_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.tile_one{
    border-left: 0;
    border-top: 0;
}
.tile_two{
    border-top: 0;
}
.tile_three{
    border-right: 0;
    border-top: 0;
}
.tile_four{
    border-left: 0;
}
.tile_six{
    border-right: 0;
}
.tile_seven{
    border-left: 0;
    border-bottom: 0;
}
.tile_eight{
    border-bottom: 0;
}
.tile_nine{
    border-right: 0;
    border-bottom: 0;
}

@media screen and (max-width:400px){
    .grid{
        width: 35rem;
    }
}


@media screen and (max-width:800px){
    .circle_img_container{
        height: 25rem;
    }
    .x_img_container{
        height: 25rem;
    }
}

@media screen and (max-width:600px){
    .circle_img_container{
        position: absolute;
        top: -5%;
        right: -10%;
        transform: translate(-5%,-10%);
        height: 15rem;
    }
    .x_img_container{
        position: absolute;
        top: -5%;
        left: -10%;
        transform: translate(-5%,-10%);
        height: 15rem;
        height: 15rem;
    }
}