.main{
    
    background: transparent;
    background: none;
    background-color: transparent;
    background: linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../staticImages/bg_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
 
}
.main2{
    display: flex;
    justify-content: space-between;
    padding-inline: 3rem;
    padding-block: 1rem;
    backdrop-filter: brightness(50%);
    position: fixed;
    z-index: 100;
    width: 100%;
    /* top: 10rem; */
}

.navItems{
    display: flex; 
}

.navItems span{
    display: flex;
    margin: 1.5rem;
    font-size: 2rem;
    color: white;
    /* width: 6rem; */
}

.navItems span:hover{
    display: flex;
    margin: 1.5rem;
    font-size: 2rem;
    color: orange;
    /* width: 6rem; */
}

.game_title{
    width: 100%;
    display: flex;
    
    align-items: center;
}
.menu{
    display: none;
    height: 3rem;
    width: 3rem;
}

.title{
    width: 28rem;
    height: 4rem;
   
}

@media only screen and (max-width:624px){
   .main2{
    display: grid;
   }

   /* .menu{
    display: inline;
    height: 1rem;
    width: 1rem;
   }

   .navItems{
    display: none;
   } */
}