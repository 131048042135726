@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Protest+Riot&display=swap');


*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

html{
    font-size: 62.5%;
}

body{
    min-height: 100dvh;
    width: 100%;
    font-family: 'Protest Revolution', sans-serif;
font-family: 'Protest Riot', sans-serif;
}