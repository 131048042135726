.container{
    background: linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../staticImages/bg_1.jpg");
     background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    gap: 2rem;
    min-height: 100dvh;
    min-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;
    overflow: hidden;
    /* position: relative; */
    align-items: center;
}


.game_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.title{
    width: 38rem;
    /* font-size: 7rem; */
    /* font-weight: 900; */
}

.accountHeading{
    font-size: 4rem;
    color: #d6d1d1;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #cd7703;
    margin-top: 4rem;
}

.animationDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.animation{
    height: 30rem;
    width: 24rem;
    
    margin: auto;
    margin-block: -50px;
    /* justify-self: center; */
  
}

.main{
    display: grid;
    justify-content: center;
}

.userName{
    font-size: 3rem;
    color: #d6d1d1;
    font-family: 'Protest Riot', sans-serif;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: black;
    background-color: #00000075;
    width: 35rem;
    padding: 0.5rem;
    text-align: center;
    border-radius: 20px;
    
    
}

.Data{
    background: #e0c9b74d;
    width: 35rem;
    margin-top: 2rem ;
    display: grid;
    /* justify-content: center; */
    align-items: center;
    padding: 2rem;
    border-radius: 20px;
    border: 1px solid black;
    backdrop-filter: brightness(40%);
    box-shadow: 0 1.5rem 1.3rem rgba(0, 0, 0, 0.511);
}

.Data p{
    font-size: 2rem;
    color: black;
    margin: 1rem;
    text-align: left;
    /* -webkit-text-stroke-width: 0.2px; */
    /* -webkit-text-stroke-color: #cd7703; */
}

.values{
    background: transparent;
    border: 1px solid black;
    backdrop-filter: brightness(70%);
    border-radius: 30px;
    width: 100%;
    padding: 0.5rem;
    padding-inline:1rem;
    font-size: 1.5rem;
    color: white;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: black;
}
