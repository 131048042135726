.leaderboard_container{
    width:100%;
    position: relative;
    overflow: hidden;
}
.leaderAnimation_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.leaderboardAnimation{
    width: 100%;
    height: 100%;
}

.leaderboard_positions_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.leaderboard_top3_positions{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width:100%;
}
.position1_animation_container,.position2_animation_container,.position3_animation_container{
    width: 10rem;
    height: 10rem;
}
.award1Animation,.award2Animation,.award3Animation{
    width: 100%;
    height: 100%;
}
.position2_animation_container,.position3_animation_container{
    margin-top: 4rem;
}

.player_info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
}
.player_text{
    width: 100%;
    font-size: 1.4rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: bold;
}

.leaderboard_table_container{
    width: 100%;
}
.leaderboard_table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}
.leaderboard_table_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30rem;
    /* background-color: #008ADD; */
    background-color: rgb(229, 128, 51);
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.5);
}
.leaderboard_column1,.leaderboard_column2,.leaderboard_column3{
    padding: 1rem;
    width: 10rem;
}
.column_name{
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #fff;
}

.leaderboard_table_data{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30rem;
    background-color: #E2E8F0;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.5);
}
.leaderboard_row1,.leaderboard_row2,.leaderboard_row3{
    padding: 1rem;
    width: 10rem;
}
.row_name{
    font-size: 1.6rem;
    font-weight: bold;
    font-family: 'Signika Negative',sans-serif;
    text-align: center;
    color: #0f1010;
}
@media screen and (max-width:600px){
    .player_text{
        font-size: 1.2rem;
    }
    .player_info{
        text-align: center;
    }
    .leaderboard_positions_container{
        gap: 3rem;
    }
}

.leaderboard_container_2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.leaderboard_container_2 h3{
    font-family: 'Signika Negative',sans-serif;
     color: #333;
     font-size: 3rem;
}