.container{
    background: linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../staticImages/bg_1.jpg");
     background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    gap: 2rem;
    min-height: 100dvh;
    min-width: 100%;
}



.sub_container{
    /* backdrop-filter: blur(5px); */
    min-height: 100dvh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100dvh;
    position: relative;
    overflow: hidden;
}

.game_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14rem;
}

.title{
    width: 35rem;
    font-size: 7rem;
    font-weight: 900;
}


.bg_img{
    position: absolute;
    width: 35rem;
    height: 30rem;
    object-fit:scale-down;
    box-shadow: 0 1.5rem 1.3rem rgba(0, 0, 0, 0.511);
}


.gridContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 4rem;
}

.grid{
    width: 33rem;
    height: 25rem;
    justify-content: center; 
    align-items: center; 
    padding: 1rem;
    z-index: 99;
    
    padding-top: 2rem ;
    border-radius: 20px;
    
}

.detail{
    margin-top: 0.5rem;
    width: 100%;
}

.label{
    font-size: 2rem;
    color:white;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: rgb(229, 128, 51);
}

.input{
    background: transparent;
    border-radius: 20px;
    width: 100%;
    height: 3.5rem;
    margin-top: 1rem;
    outline: 1px solid black;
    font-size: large;
    color: white;
    padding-left: 2rem;
    font-family: 'Protest Riot', sans-serif;
    /* padding: 2rem; */
    backdrop-filter: brightness(60%);
}

.btnDiv{
    margin-top: 1.5rem;
}

.btn{
    width: 100%;
    height: 4rem;
    border-radius: 20px;
    background-color: #cd7703;
    font-size: 2rem;
    cursor: pointer;
    border: 1px solid black;
    transition: all 0.3s;
    font-family: 'Protest Riot', sans-serif;
    box-shadow: 0 1.5rem 1.3rem rgba(0, 0, 0, 0.511);
}

.btn:hover{
    background-color: #db9200;
}
.link_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.link{
   /* color: rgb(158, 108, 6); */
   color: white;
   font-size: 1.3rem;
   font-family: 'Montserrat',sans-serif;
   text-decoration: underline;
}