@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Protest+Riot&display=swap');

.container{
    background: linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../staticImages/bg_1.jpg");
     background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    min-height: 100dvh;
    position: relative;
    overflow: hidden;
}

.game_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14rem;
}
.title_image{
    width: 35rem;
    height: 5rem;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.game_mode_container{
    width: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem 1rem 4rem 1rem;
    border-radius: 20px;
    gap: 2rem;
    background: linear-gradient(to right,rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url("../staticImages/bg_2.jpg");
  background-size: cover;
  opacity: 0.9;
  z-index: 0;
  box-shadow: 0 1.5rem 1.3rem rgba(0, 0, 0, 0.511);
  overflow: hidden;
}

.levels_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.points_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    background-color: #cd7703;
    padding: 1rem;
    font-size: 2rem;
    font-family: 'Protest Riot', sans-serif;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.4);
    cursor: pointer;
}

.point_image{
    width: 2rem;
    height: 2rem;
    object-fit: contain;
}

.easy_btn{
    background-color: #cd7703;
    outline: none;
    border: none;
    padding: 1rem 1rem 1rem 1rem;
    width: 20rem;
    font-size: 2rem;
    font-family: 'Protest Riot', sans-serif;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.4);
    cursor: pointer;
    transition: all 0.3s;
    z-index: 900;
}
.medium_btn{
    background-color: #202F3F;
    outline: none;
    border: none;
    padding: 1rem 1rem 1rem 1rem;
    width: 20rem;
    font-size: 2rem;
    font-family: 'Protest Riot', sans-serif;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.4);
    cursor: pointer;
    transition: all 0.3s;
    z-index: 900;
}

.easy_btn:hover{
    background-color:#db9200;
}
.medium_btn:hover{
    background-color: #10161c;
}

.easy_btn:active{
    transform: scale(1.1);
}
.medium_btn:active{
    transform: scale(1.1);
}